import { extractMessage, extractPollChoices, extractQuestion } from './../helpers';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_POLL': {
      console.log(action.data);
      return {
        ...state,
        poll: action.data,
        question: extractQuestion(action.data, action.locale),
        pollChoices: extractPollChoices(action.data, action.locale, action.pollType),
        type: action.pollType,
        matchCentreUrl: action.matchCentreUrl,
        customisation: {
          poll: {
            backgroundColor: action.data.backgroundColor || 'rgb(167, 21, 128)',
            backgroundImageUrl: action.data.backgroundImageUrl || '',
            headerImageUrl: action.data.headerImageUrl || '',
            voteSuccessMessage:
              action.data.voteSuccessMessage !== null
                ? extractMessage(action.data.voteSuccessMessage, action.locale)
                : 'Thanks for your vote!'
          },
          question: {
            backgroundColor:
              (action.data.questionTextCustomisation && action.data.questionTextCustomisation.backgroundColor) ||
              '#fff',
            color:
              (action.data.questionTextCustomisation && action.data.questionTextCustomisation.color) ||
              'rgb(167, 21, 128)',
            font:
              (action.data.questionTextCustomisation && action.data.questionTextCustomisation.font) || 'PoppinsRegular',
            marginTop: action.data.marginTop || '40',
            fontSize: (action.data.questionTextCustomisation && action.data.questionTextCustomisation.fontSize) || 24
          },
          choices: {
            backgroundColor:
              (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.backgroundColor) || '#fff',
            color:
              (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.color) ||
              'rgb(167, 21, 128)',
            font:
              (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.font) || 'PoppinsRegular',
            fontSize: (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.fontSize) || 18,
            selectedBackgroundColor:
              (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.selectedBackgroundColor) ||
              '#d6aa46ff',
            selectedColor:
              (action.data.choicesTextCustomisation && action.data.choicesTextCustomisation.selectedColor) ||
              '#c32b2bff'
          }
        }
      };
    }

    case 'SET_VOTE': {
      return {
        ...state,
        voted: action.value,
        choiceId: action.id,
        choiceText: action.text,
        choiceImageURL: action.image,
        showVoteCompletedModal: false
      };
    }

    case 'CLEAR_VOTE': {
      return {
        ...state,
        voted: action.value,
        choiceId: null,
        showVoteCompletedModal: false
      };
    }

    default:
      return state;
  }
};
