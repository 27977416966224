import axios from 'axios';
import { localeText } from './locale';
import Headshot from '../assets/players/headshot.png';

export const playerPosition = (player) => {
  switch (player.Position) {
    case 0:
      return 'GoalKeeper';
    case 1:
      return 'Defender';
    case 2:
      return 'Midfielder';
    case 3:
      return 'Forward';
    default:
      return 'Player';
  }
};

export const getSquads = async (teamsArray, seasonId, competitionId) => {
  const teamArrayEx = await Promise.all(
    await teamsArray.map(async (teamId) => {
      return await axios
        .get(`https://api.fifa.com/api/v3/teams/${teamId}/squad/all/${competitionId}`)
        .then((res) => res.data.Results.filter((squad) => squad.IdSeason === seasonId)[0]);
    })
  );
  return teamArrayEx;
};

export const teamName = (team = null) => {
  if (team) {
    return team.TeamName[0].Description;
  } else {
    return null;
  }
};

export const teamFlag = (team = null) => {
  // https://cdn.static.pushologies.com/fifa/flags/Nations_Germany.png
  if (team) {
    return `https://cdn.static.pushologies.com/fifa/flags/Nations_${team.TeamName[0].Description}.png`;
  } else {
    return null;
  }
};

export const playerName = (player = null) => {
  if (player) {
    return player.ShortName[0].Description;
  } else {
    return null;
  }
};

export const playerImage = (player) => {
  if (player && player.PlayerPicture.PictureUrl) {
    return `${player.PlayerPicture.PictureUrl}?io=transform:fill,width:500,height:500`;
  } else {
    return Headshot;
  }
};

export const matchDate = (date) => {
  if (date) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString([], options);
  } else {
    return null;
  }
};

export const isIOS = () => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const getText = (locale) => {
  let appText = {};

  localeText.forEach((lang) => {
    if (locale.match(/^(EN|AR|DE|ES|FR|IT|PT)$/)) {
      appText = { ...appText, [lang.title]: lang.text[locale] };
    } else {
      appText = { ...appText, [lang.title]: lang.text['EN'] };
    }
  });
  return appText;
};
