import React from 'react';
import './assets/fonts/fonts.css';
import '../index.css';
import { StateProvider } from './state';
import { AppDiv, GlobalStyles, Wrapper } from './styles';
import { StartModal } from './start-modal';

export const Polling = () => {
  return (
    <AppDiv>
      <GlobalStyles />
      <StateProvider>
        <Wrapper>
          <StartModal />
        </Wrapper>
      </StateProvider>
    </AppDiv>
  );
};
