import React, { useState, useCallback, createRef } from 'react';
import domtoimage from 'dom-to-image';
import { toPng } from 'html-to-image';
import { useStateContext, useStateDispatchContext } from '../state';
import { teamName, matchDate, isIOS } from '../helpers';
import { FormationRow } from './row';
import { ShareModal } from '../share-modal';
import { Header } from '../header';
import {
  FormationPage,
  FormationDiv,
  FieldSection,
  ButtonSection,
  StyledSelect,
  SaveButton,
  BannerDiv
} from './styles';
import BannerIMG from '../assets/banner.png';
import FieldImage from '../assets/field.svg';

const formationOptions = [
  { value: [4, 4, 2], label: '4-4-2' },
  { value: [4, 5, 1], label: '4-5-1' },
  { value: [4, 3, 3], label: '4-3-3' },
  { value: [3, 5, 2], label: '3-5-2' },
  { value: [4, 4, 1, 1], label: '4-4-1-1' },
  { value: [4, 3, 2, 1], label: '4-3-2-1' },
  { value: [3, 3, 3, 1], label: '3-3-3-1' },
  { value: [4, 2, 3, 1], label: '4-2-3-1' },
  { value: [4, 1, 2, 1, 2], label: '4-1-2-1-2' }
];

function saveDisabled(formation) {
  for (let i = 0; i < formation.length; i++) {
    for (let j = 0; j < formation[i].length; j++) {
      if (!Boolean(formation[i][j])) {
        return true;
      }
    }
  }
}

export const Formation = () => {
  const [downloading, setDownloading] = useState(false);
  const { formation, showShareModal, selectedTeam, opponent, date, text } = useStateContext();
  const { dispatch } = useStateDispatchContext();
  const ref = createRef(null);

  const onShareClick = useCallback(() => {
    setDownloading(true);
    let refr = ref.current;

    if (ref.current === null) {
      return;
    } else {
      if (isIOS()) {
        domtoimage
          .toJpeg(refr, {
            cacheBust: true,
            bgcolor: '#550065'
          })
          .then((dataURL) => {
            domtoimage
              .toJpeg(refr, {
                cacheBust: true,
                bgcolor: '#550065'
              })
              .then((dataURL2) => {
                const link = document.createElement('a');
                link.download = 'FIFA-WC-2022-match-prediction.jpeg';
                link.href = dataURL2;
                link.click();
                setDownloading(false);
              });
          });
      } else {
        toPng(refr, { cacheBust: true, backgroundColor: '#550065' }).then((dataURL) => {
          const link = document.createElement('a');
          link.download = 'FIFA-WC-2022-match-prediction.png';
          link.href = dataURL;
          link.click();
          setDownloading(false);
        });
      }
    }
  }, [ref]);

  const handleFormationChange = ({ value }) => {
    dispatch({ type: 'FORMATION_CHANGE', value });
  };

  const handleClear = () => {
    dispatch({ type: 'CLEAR_SELECTION' });
  };

  const handleBack = () => {
    dispatch({ type: 'CLEAR_TEAM' });
  };

  const handleSave = () => {
    dispatch({ type: 'SET_SHOW_SHARE_MODAL', show: true });
  };

  return (
    <>
      {selectedTeam && Object.keys(selectedTeam).length && (
        <FormationPage ref={ref} id="screenshot" className="formationPage">
          <Header showShareModal={showShareModal} />
          <FormationDiv>
            {!showShareModal ? (
              <>
                <p className="note">{text.lineupHeader}</p>
                {/* <p className="note">{text.deadlineHeader}</p> */}
              </>
            ) : (
              <>
                <p className="note turq">{text.screenshotTitle}</p>
                <p className="note white">
                  {teamName(selectedTeam)} v {teamName(opponent)} <br></br> {matchDate(date)}
                </p>
              </>
            )}

            <FieldSection>
              <img className="fieldImg" src={FieldImage} alt="field" />
              <div className="players">
                {formation.map((players, index) => (
                  <FormationRow key={index} players={players} columnIndex={index} />
                ))}
              </div>
            </FieldSection>

            {!showShareModal && (
              <>
                <ButtonSection>
                  <StyledSelect
                    onChange={handleFormationChange}
                    options={formationOptions}
                    placeholder={text.formationChangeLabel}
                    classNamePrefix="rs"
                    menuPlacement="top"
                  />
                  <button onClick={handleClear}>{text.clearText}</button>
                </ButtonSection>
                <ButtonSection>
                  <SaveButton onClick={handleBack}>Back</SaveButton>
                  <SaveButton onClick={handleSave} disabled={saveDisabled(formation)}>
                    {text.downloadButton}
                  </SaveButton>
                </ButtonSection>
                <BannerDiv>
                  <img src={BannerIMG} alt="#BelievingIsMagic" />
                </BannerDiv>
              </>
            )}
          </FormationDiv>
        </FormationPage>
      )}
      {showShareModal && <ShareModal downloading={downloading} onShare={onShareClick} />}
    </>
  );
};
