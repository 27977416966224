export const nativeEventBridgeHelper = {
  emitTagEvent: async (tagName) => {
    const payload = JSON.stringify({
      type: 'assign',
      data: {
        tagName
      }
    });

    try {
      if (window.PushSDK) {
        // android
        window.PushSDK.emitTagEvent(payload);
      } else {
        // iOS
        window.webkit.messageHandlers.PushSDK.postMessage(payload);
      }
    } catch (e) {}
  }
};
