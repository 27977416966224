import React from 'react';
import { Formation } from './formation';
import { PlayerSelect } from './player-select';
import { StartModal } from './start-modal';
import { StateProvider } from './state';
import './assets/fonts/fonts.css';
import { AppDiv, GlobalStyles, Wrapper } from './styles';

export const Predictor = () => {
  return (
    <AppDiv>
      <GlobalStyles />
      <div id="gradientBg"></div>
      <StateProvider>
        <Wrapper>
          <StartModal />
          <PlayerSelect />
          <Formation />
        </Wrapper>
      </StateProvider>
    </AppDiv>
  );
};
