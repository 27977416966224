import styled from 'styled-components';
import { getFont } from '../helpers';

export const ModalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  padding: 0;
  background: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
`;

export const StartSection = styled.section`
  width: 100%;
  height: 100%;
  position: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: ${getFont()};
  color: white;
  background-color: ${(props) => props.customisation.backgroundColor};
  background-image: url(${(props) => props.customisation.backgroundImageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .modal_body {
    position: inherit;
    z-index: 11;
    padding: 1.5rem;

    img {
      max-height: 150px;
    }
  }

  .modal_footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    bottom: 2rem;

    button {
      width: 80%;
      padding: 1rem;
      margin: 0.5rem auto;
      border: none;
      color: ${(props) => props.customisation.backgroundColor};
      background-color: #fff;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
    }

    h2 {
      margin: 0.65rem;
    }
  }
`;

export const QuestionDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  background-color: ${(props) => props.customisation.backgroundColor};
  margin-top: ${(props) => `${props.customisation.marginTop}px`};

  h1 {
    text-align: center;
    max-width: 90%;
    color: ${(props) => props.customisation.color};
    font-size: ${(props) => `${props.customisation.fontSize}px`};
    font-family: ${(props) => getFont(props.customisation.font)};
  }
`;

export const AnswerSelectDiv = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0;

  p {
    padding: 1rem;
    margin: 0.75rem 0;
    text-align: center;
    font-size: ${(props) => `${props.customisation.fontSize}px`};
    font-family: ${(props) => getFont(props.customisation.font)};
    color: ${(props) => props.customisation.color};
    background-color: ${(props) => props.customisation.backgroundColor};

    &:hover {
      color: ${(props) => props.customisation.selectedColor};
      background-color: ${(props) => props.customisation.selectedBackgroundColor};
    }
  }

  div {
    margin: 0;
  }
`;
