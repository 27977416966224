import React from 'react';
import AddPlayerIcon from '../assets/add_player.svg';
import { useStateDispatchContext } from '../state';
import { FormationRowDiv, PlayerFigure } from './styles';
import { playerName, playerImage } from '../helpers';

// let formatName = (name) => {
//   const nameArray = name.split(" ");
//   let lastName = nameArray[1];
//   return lastName;
// }

export const FormationRow = ({ players, columnIndex }) => {
  const { dispatch } = useStateDispatchContext();

  const handlePlayerClick = (rowIndex) => () => {
    dispatch({ type: 'SET_ACTIVE_POSITION', position: [columnIndex, rowIndex] });
  };

  return (
    <FormationRowDiv>
      {players.map((player, index) => (
        <PlayerFigure assigned={!!player} key={playerName(player) || index} onClick={handlePlayerClick(index)}>
          {playerName(player) ? (
            <>
              <img src={playerImage(player)} alt="Player" />
              <span>{playerName(player)}</span>
            </>
          ) : (
            <img src={AddPlayerIcon} alt="Player" />
          )}
        </PlayerFigure>
      ))}
    </FormationRowDiv>
  );
};
