import styled from 'styled-components';

export const ModalDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(244,0,9,1);
  background-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0.75));
  transition: background-image 0.5s;
  z-index: 30;

  section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 2rem;
    font-family: QatarRegular;

    h2 {
      margin-bottom: 0.5rem;
    }

    p {
      width: 90%;
      margin: 0 auto;
      text-align: center;
    }

    img {
      height: 16rem;
    }
  }

  .lockup {
    text-align: center;
    padding: 1rem 0;

    img {
      height: 10rem;
    }
  }
  .promise {
    width: 100%;

    img {
      width: 90%;
      height: auto;
      background-color: #fff;
    }
  }
`;

export const ShareButton = styled.button`
  position: fixed;
  display: block;
  background-color: transparent;
  background-image: url(${({ bg }) => bg}) ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: rgba(244,0,9,1);
  border: none;
  width: 90%;
  padding: 1rem;
  margin: 1rem auto;
  bottom: 2rem;
  cursor: pointer;
  font-family: QatarRegular;
  font-size: 1.5rem;

  &:disabled {
    background: rgba(85, 0, 101, 0.1);
    cursor: default;
  }

  &:not(&:disabled) {
    &:hover {
      background-color: transparent;
      background-image: url(${({ bg }) => bg}) ;
    }
  }
`;
