import React from 'react';
import DownloadIcon from '../assets/download.svg';
import DownloadingIcon from '../assets/downloading.svg';
import Lockup from '../assets/lockup-colour.png';
import { useStateContext, useStateDispatchContext } from '../state';
import { ShareButton } from './styles';
import { ModalDiv } from './styles';

export const ShareModal = (props) => {
  const { showShareModal, text } = useStateContext();
  const { dispatch } = useStateDispatchContext();

  const handleCancel = () => {
    dispatch({ type: 'SET_SHOW_SHARE_MODAL', show: false });
  };

  return (
    <ModalDiv show={showShareModal}>
      <section>
        <h2>{text.downloadButton}</h2>
        <p>{text.downloadText}</p>
        <ul>
          <li>
            <figure>
              {!props.downloading ? (
                <img src={DownloadIcon} alt="download" onClick={props.onShare} />
              ) : (
                <img src={DownloadingIcon} alt="downloading" />
              )}
            </figure>
          </li>
        </ul>
        <div className="logo">
          <img src={Lockup} alt="World Cup Logo" />
        </div>
        <ShareButton onClick={handleCancel}>Cancel</ShareButton>
      </section>
    </ModalDiv>
  );
};
