import React, { useEffect } from 'react';
import { useStateContext, useStateDispatchContext } from '../state';
import { ModalDiv, AnswerSelectDiv, QuestionDiv, StartSection } from './styles';
import { ShareModal } from '../share-modal';
import { nativeEventBridgeHelper } from '../helpers/native-event-bridge.helper';

export const StartModal = () => {
  const { poll, question, pollChoices, customisation, voted, showVoteCompletedModal } = useStateContext();
  const { fetchPoll, submitVote } = useStateDispatchContext();

  useEffect(() => {
    // set title for this specific web app
    document.title = 'Pushologies | Poll';
    fetchPoll();
  }, []);

  const handleVote = async (vote_choice) => {
    await submitVote(vote_choice);

    // do we have a tag that we've associated with this answer?
    // we'll need to tell the SDK of the tag to apply
    if (vote_choice.tagName) {
      nativeEventBridgeHelper.emitTagEvent(vote_choice.tagName);
    }
  };

  return (
    <ModalDiv>
      {poll ? (
        <StartSection customisation={customisation.poll}>
          <div className="modal_body">
            {customisation.poll.headerImageUrl && customisation.poll.headerImageUrl !== '' && (
              <img src={customisation.poll.headerImageUrl} alt="Poll" />
            )}
            <QuestionDiv customisation={customisation.question}>
              <h1>{question.value}</h1>
            </QuestionDiv>
            {voted ? (
              <div className="modal_footer">
                <h2>{customisation.poll.voteSuccessMessage}</h2>
                {/* <button onClick={() => (window.location.href = matchCentreUrl)}>
                  Reset
                </button> */}
              </div>
            ) : (
              <AnswerSelectDiv customisation={customisation.choices}>
                {pollChoices.map((choice, id) => (
                  <div key={choice.id} onClick={() => handleVote(choice)}>
                    <p>{choice.option.value}</p>
                  </div>
                ))}
              </AnswerSelectDiv>
            )}

            {/* <p>Tap a button to select your choice</p> */}
          </div>
        </StartSection>
      ) : (
        <p>loading</p>
      )}
      {showVoteCompletedModal ? <ShareModal /> : null}
    </ModalDiv>
  );
};
