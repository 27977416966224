import React, { useEffect } from 'react';
import { useStateContext, useStateDispatchContext } from '../state';
import { ModalDiv, TeamSelectDiv, HeaderDiv } from './styles';
import { teamName, teamFlag, matchDate } from '../helpers';
import FWCLogo from '../assets/lockup-white.png';
import FWCMosaicTR from '../assets/mosaic_tr.png';
import FWCMosaicBL from '../assets/mosaic_bl.png';
import FWCMosaicBR from '../assets/mosaic_br.png';

export const StartModal = () => {
  const { teams, stadium, date, stage, text, showStartModal } = useStateContext();
  const { fetchTeams, setTeam, dispatch } = useStateDispatchContext();

  useEffect(() => {
    document.title = 'Pushologies | Predictor';
    fetchTeams();
  }, []);

  const handleSubmit = (index) => {
    setTeam(index);
    dispatch({ type: 'SET_SHOW_START_MODAL', show: false });
  };

  return (
    <ModalDiv show={showStartModal}>
      <section>
        {teams && text ? (
          <>
            <HeaderDiv>
              <img className="logo" src={FWCLogo} alt="FIFA World Cup Logo" />
              <img className="tr" src={FWCMosaicTR} alt="FIFA World Cup Mosaic" />
              <img className="bl" src={FWCMosaicBL} alt="FIFA World Cup Mosaic" />
              <img className="br" src={FWCMosaicBR} alt="FIFA World Cup Mosaic" />
            </HeaderDiv>
            <div className="modal_body">
              <section>
                <h1>{text.appTitle}</h1>
                <h2>{stage}</h2>
                <h3>{stadium}</h3>
                <h3>{matchDate(date)}</h3>
              </section>

              <TeamSelectDiv>
                {teams.map((team, id) => (
                  <div key={teamName(team) + id} onClick={() => handleSubmit(id)}>
                    <img src={teamFlag(team)} alt={teamName(team)} />
                    <p>{teamName(team)}</p>
                  </div>
                ))}
              </TeamSelectDiv>

              <p>{text.teamSelect}</p>
            </div>
          </>
        ) : (
          <p>loading</p>
        )}
      </section>
    </ModalDiv>
  );
};
